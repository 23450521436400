"use client";

import Script from "next/script";
import Link from "next/link";
import Image from "next/image";

function EmbeddedScripts() {
  return (
    <>
      <Script id="cbsBookingAppLoaded">
        {`
            cbsBookingAppLoaded = function () {
              window.websiteSettings.pixelsFromTopDelta = 0;
              window.websiteSettings.showFlightInfoWhenBooking = true;
              window.websiteSettings.showFlightInfoOnlyWhenDeparting = false;
              window.websiteSettings.preferFlightTimeWhenAvailable = false;
              window.websiteSettings.bookingPageUrl = "/booking";
              window.websiteSettings.headerElements = ["#theme-menu-layout"];
            };
        `}
      </Script>
      <Script src="https://js.stripe.com/v3/" />
      {/* 
      {typeof window !== "undefined" ? (
        !window.smartlook ? (
          <Script id="smartlookscript" async strategy="afterInteractive">
            {`
              window.smartlook||(function(d) {
                var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
                var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
                c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
              })(document);
              smartlook('init', '6d2677eaf7cf5a9658ecf5be6da43f99eae9ea72', { region: 'eu' });
              smartlook('record', { forms: true, numbers: false, emails: true, ips: true });
            `}
          </Script>
        ) : (
          <></>
        )
      ) : (
        <></>
      )} */}

      {/* TAPFILIATE */}
      <Script
        src="https://script.tapfiliate.com/tapfiliate.js"
        type="text/javascript"
        async
      />
      <Script id="tapfiliatePaypalScript">
        {`
            (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){
              (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
              function tapGetQueryParams(){var r={},t=function(r){return decodeURIComponent(r.replace(/\+/g," "))},n=location.search.substring(1).split("&");for(var e in n){var a=n[e].toString().split("=");a.length>1&&(r[t(a[0])]=t(a[1]))}return r}
          
              var params = tapGetQueryParams();
          
              tap('create', '48848-f2d669', { integration: "paypal" });
          
              if (params.st == 'Completed') {
                var amt = params.amt || 1;
                var tx = params.tx || null;
                tap('conversion', tx,  amt);
              } else {
                tap('detect');
              }
          `}
      </Script>

      {/* @ts-ignore */}
      {/* <Script src="//fw-cdn.com/1*****7/2*****4.js" chat="true" />
        <Script id="freshChat">
          {`
            function initFreshChat() {
              window.fcWidget.init({
                  token: "cfc5e39e-65fb-48aa-a2c7-f0f46a92966c",
                  host: "https://travelthru.freshchat.com",
                  config: {
                    eagerLoad: true
                  }
              });
            }

            function initialize(i,t){var e;i.getElementById(t)?
            initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,
            e.src="https://travelthru.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
            function initiateCall(){initialize(document,"Freshchat-js-sdk")}
            window.addEventListener?window.addEventListener("load",initiateCall,!1):
            window.attachEvent("load",initiateCall,!1);
        `}
        </Script> */}

      <Script id="tapfiliateStripeScript">
        {`
              (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){
              (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
        
              tap('create', '48848-f2d669', { integration: "stripe" });
              tap('detect');
          `}
      </Script>

      <Link
        href="https://support.travelthru.com/en/support/home"
        className=" bg-black h-[60px] w-[60px] hover:scale-110 transition-transform fixed bottom-20 right-5 z-50
             flex items-center justify-center shadow-lg"
        target="_blank"
        style={{ borderRadius: "30px 10px 30px 30px" }}
      >
        <Image
          alt="chat"
          src="/assets/images/chatbubble-ellipses-sharp.svg"
          width={30}
          height={30}
          className="w-[25px] h-[25px] fill-white "
          style={{ filter: "brightness(0) invert(1)" }}
        />
      </Link>
    </>
  );
}

export default EmbeddedScripts;
