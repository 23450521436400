"use client";

import React, { useEffect, useState, ReactNode } from "react";

interface GoogleMapsLoaderProps {
  children: ReactNode;
  apiKey: string;
}

declare global {
  interface Window {
    google?: {
      maps: any;
    };
  }
}

const GoogleMapsLoader: React.FC<GoogleMapsLoaderProps> = ({
  children,
  apiKey,
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const isGoogleMapsLoaded = (): boolean => {
      return (
        typeof window.google === "object" && typeof window.google.maps === "object"
      );
    };

    if (!isGoogleMapsLoaded() && !error) {
      const scriptId = "google-maps-script";
      const existingScript = document.getElementById(scriptId);

      if (!existingScript) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.src = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${apiKey}`;
        script.async = true;
        script.defer = true;
        script.onload = () => setIsLoaded(true);
        script.onerror = () => setError(true);
        document.head.appendChild(script);

        return () => {
          // Cleanup function to remove the script if the component unmounts before loading
          document.head.removeChild(script);
        };
      } else {
        setIsLoaded(true);
      }
    } else {
      setIsLoaded(true);
    }
  }, [error, apiKey]);

  if (error) {
    return <div>Error loading Google Maps</div>;
  }

  return isLoaded ? <>{children}</> : <div></div>;
};

export default GoogleMapsLoader;